
import Vue from 'vue'

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'EULA',
  head () : object {
    return {
      titleTemplate: '%s | ' + this.$t('documents.eula')
    }
  }
})
