
import Modal from './Modal'
import { Modpack } from '~/types/core'

// noinspection JSUnusedGlobalSymbols
export default Modal.extend({
  name: 'PermInfoModal',
  data () {
    return {
      name: 'PermInfoModal',
      selectedModpack: 0
    }
  },
  watch: {
    show (v: boolean) {
      if (v) {
        this.updateModpack()
      }
    }
  },
  methods: {
    updateModpack () {
      if (this.modpacks.length <= 0) {
        return
      }
      const modpackId = this.modpackId
      if (modpackId) {
        this.selectedModpack = modpackId
      } else {
        this.selectedModpack = this.modpacks[0].id
      }
    }
  },
  computed: {
    modpackId (): number {
      return this.$store.getters['modals/data'] || 0
    },
    modpack (): Modpack | undefined {
      return this.modpacks.find(a => a.id === this.selectedModpack)
    },
    modpacks (): Modpack[] {
      return this.$store.getters['core/modpacks']
    },
    modpackItems (): Record<string, unknown>[] {
      const a: Record<string, unknown>[] = []
      for (const modpack of this.modpacks) {
        a.push({
          text: `${modpack.name} ${modpack.minecraft?.version}`,
          value: modpack.id
        })
      }
      return a
    }
  }
})
