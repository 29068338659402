
import Vue from 'vue'

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'DataHandleAgreement',
  head () : object {
    return {
      titleTemplate: '%s | ' + this.$t('documents.data_handle')
    }
  }
})
