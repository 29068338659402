
import Vue from 'vue'
import { Monitoring } from '~/types/core'

export default Vue.extend({
  name: 'Hero',
  computed: {
    monitoring (): Monitoring {
      return this.$store.getters['core/monitoring']
    }
  },
  methods: {
    showDownload () {
      this.$store.dispatch('modals/show', { name: 'download' }).then().catch(console.error)
    },
    showRules () {
      this.$store.dispatch('modals/show', { name: 'modpackRules' }).then().catch(console.error)
    }
  }
})
