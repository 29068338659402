
import Vue from 'vue'
import { get_error_message, noWait } from '~/utils/misc'

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'RestoreAccess',
  layout: 'form',
  middleware: 'auth',
  auth: 'guest',
  data () {
    return {
      form: {
        value: false,
        login: ''
      },
      loginRules: [
        (v: string) => !!v || this.$t('pages.restoreAccess.rules.login.empty'),
        (v: string) => v.length >= 4 || this.$t('pages.restoreAccess.rules.login.short'),
        (v: string) => {
          if (v.length <= 24 && v.match(/^[a-zA-ZА-яёË\d\-_][a-zA-ZА-яёË\d\-_.]{1,22}[a-zA-ZА-яёË\d\-_]$/)) {
            return true
          } else if (v.length <= 254) {
            const at = v.indexOf('@')
            if (at >= 1 && at < v.length) {
              return true
            } else {
              return this.$t('pages.restoreAccess.rules.login.malformed')
            }
          } else {
            return this.$t('pages.restoreAccess.rules.login.longEmail')
          }
        }
      ]
    }
  },
  head (): object {
    return {
      titleTemplate: this.$t('titles.restoreAccess')
    }
  },
  created () {
    const redirectTo = this.$route.query['redirect-to']
    if (typeof redirectTo !== 'undefined') {
      this.$cookies.set('redirectTo', redirectTo)
    }
  },
  methods: {
    async onSubmit () {
      if (!this.form.value) {
        return
      }
      try {
        const res = await this.$loader(this.restoreAccess(), {
          timeout: 0,
          immediate: true
        })
        if (typeof res === 'object') {
          this.$go(`/restore-access-check#token=${res.token}&email=${res.email}`)
        }
      } catch (e: any) {
        noWait(this.$makeSad({ text: this.$_(get_error_message(e)) }))
      }
    },
    async restoreAccess (): Promise<boolean | { token: string, email: string }> {
      if (this.$auth.loggedIn) {
        await this.$makeAware({
          text: 'Already authorized',
          timeout: 3000
        })
        this.$go('/')
        return false
      }
      try {
        await this.$axios.post(
          '/me/auth/restore-access',
          {
            login: this.form.login
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
      } catch (e: any) {
        if (e?.response?.status === 401) {
          return e.response.data.error
        }
        throw e
      }
      throw new Error('Unknown error')
    }
  }
})
