
import Vue from 'vue'
import _ from 'lodash'

export default Vue.extend({
  name: 'LandingNav',
  data: () => ({
    profileDropDown: false,
    lootboxCount: 0,
    intervalLootboxCount: 0
  }),
  head () {
    return {
      meta: [{
        name: 'theme-color',
        content: (this.$vuetify.theme.currentTheme?.card || '#121214') as string
      }]
    }
  },
  computed: {
    logo (): string {
      return '/img/icons/logo' + (this.$vuetify.theme.dark ? '' : '-black') + '.svg'
    }
  },
  async created () {
    await this.updateLootboxCount()
  },
  mounted () {
    this.intervalLootboxCount = window.setInterval(this.updateLootboxCount, 15000)
  },
  beforeDestroy () {
    window.clearInterval(this.intervalLootboxCount)
  },
  methods: {
    async updateLootboxCount () {
      if (this.$auth.loggedIn) {
        const { count } = await this.$store.dispatch('lootboxes/getLootboxesOpened')
        this.lootboxCount = count
      } else {
        this.lootboxCount = 0
      }
    },
    async logout () {
      await this.$auth.logout()
    },
    showDownload () {
      this.$store.dispatch('modals/show', { name: 'download' }).then().catch(console.error)
    },
    showHelp () {
      this.$router.push({
        path: this.$route.path,
        hash: this.$route.hash,
        query: _.merge(this.$route.query, { help: 'show' })
      })
      this.$store.dispatch('modals/show', { name: 'help' }).catch(console.error)
    }
  }
})
