
import _ from 'lodash'
import Modal from './Modal'

export default Modal.extend({
  name: 'HelpModal',
  data () {
    return {
      name: 'help'
    }
  },
  mounted () {
    const help = this.$route.query.help as typeof this.$route.query.help | undefined
    if (help !== undefined) {
      this.show = true
    }
  },
  methods: {
    async hide () {
      await this.$router.push({
        path: this.$route.path,
        hash: this.$route.hash,
        query: _.merge(this.$route.query, { help: '' })
      })
      this.show = false
    }
  }
})
