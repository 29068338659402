import { render, staticRenderFns } from "./form.vue?vue&type=template&id=6adc885a&scoped=true&lang=pug&"
import script from "./form.vue?vue&type=script&lang=ts&"
export * from "./form.vue?vue&type=script&lang=ts&"
import style0 from "./form.vue?vue&type=style&index=0&id=6adc885a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6adc885a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoaderModal: require('/app/components/LoaderModal.vue').default,Alerts: require('/app/components/Alerts.vue').default})
