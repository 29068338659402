
import Vue from 'vue'
import { AlertOpts, AlertsOptions } from '~/types/alerts'

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'Alerts',
  data () {
    return {
      show: false
    }
  },
  computed: {
    showModal (): boolean {
      return this.$store.getters['alerts/visible']
    },
    opts (): AlertOpts {
      return this.$store.getters['alerts/options']
    },
    options (): AlertsOptions {
      const _opt = this.opts
      const opt: AlertsOptions = {
        type: _opt.type,
        confirm: _opt.confirm || this.$t('modals.alerts.confirm'),
        timeout: _opt.timeout || 0,
        resolve: _opt.resolve || null,
        reject: _opt.reject || null
      }
      if (opt.type === 'success') {
        opt.title = _opt.title || this.$t('modals.alerts.success.title')
        opt.text = _opt.text || this.$t('modals.alerts.success.text')
      } else if (opt.type === 'error') {
        opt.title = _opt.title || this.$t('modals.alerts.error.title')
        opt.text = _opt.text || this.$t('modals.alerts.error.text')
      } else if (opt.type === 'warning') {
        opt.title = _opt.title || this.$t('modals.alerts.warning.title')
        opt.text = _opt.text || this.$t('modals.alerts.warning.text')
      } else if (opt.type === 'prompt') {
        opt.title = _opt.title || this.$t('modals.alerts.prompt.title')
        opt.text = _opt.text || this.$t('modals.alerts.prompt.text')
        opt.cancel = _opt.cancel || this.$t('modals.alerts.prompt.cancel')
        opt.confirm = _opt.confirm || this.$t('modals.alerts.prompt.confirm')
      }
      return opt
    }
  },
  watch: {
    showModal (after) {
      if (this.show !== after) {
        this.show = after
      }
    },
    show (after) {
      if (!after) {
        this.$store.dispatch('alerts/hide')
      } else if (this.options.type !== 'prompt' && this.options.timeout) {
        window.setTimeout(this.onConfirm, this.options.timeout)
      }
    }
  },
  methods: {
    onReject (event: Event) {
      if (this.options.type !== 'prompt' && this.options.timeout) {
        return
      }
      this.show = false
      if (this.options.reject) {
        this.options.reject(event)
      }
    },
    onBack () {
      this.show = false
      if (this.options.resolve) {
        this.options.resolve(false)
      }
    },
    onConfirm () {
      this.show = false
      if (this.options.resolve) {
        this.options.resolve(true)
      }
    }
  }
})
