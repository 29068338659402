
import Vue from 'vue'
import { User } from '~/types/user'
import ListNav from '~/components/ListNav.vue'

export default Vue.extend({
  name: 'Profile',
  components: { ListNav },
  middleware: 'auth',
  data () {
    return {
      // discordClientId: '484064447795560450',
    }
  },
  head (): object {
    return {
      titleTemplate: this.$t('titles.profile')
    }
  },
  computed: {
    user (): User {
      return this.$auth.user as unknown as User
    }
  },
  methods: {
    // async linkDiscord() {
    //   const loc = document.location;
    //   const linker = window.open('https://discordapp.com/api/oauth2/authorize?client_id=' + this.discordClientId +
    //     '&redirect_uri=' + encodeURIComponent(loc.protocol + '//' + loc.host + '/#link_discord') +
    //     '&response_type=code&scope=identify&response_type=token', '_blank',);
    //
    //   if (linker !== null) {
    //     const timer = setInterval(async () => {
    //       if (linker.closed) {
    //         clearInterval(timer)
    //         await this.$auth.fetchUser()
    //         if (this.user.discord_id !== null) {
    //           await this.$makeHappy({text: this.$t('messages.discordLinked')})
    //         } else {
    //           await this.$makeAware()
    //         }
    //       }
    //     }, 500)
    //   }
    // },
    // async unlinkDiscord() {
    //   const res = await this.$axios.delete('/minecraft/user/discord')
    //   if (res.status === 200) {
    //     await this.$makeHappy(this.$t('messages.discordUnlinked'))
    //     await this.$auth.fetchUser()
    //   } else {
    //     await this.$makeSad({text: res.data || null})
    //   }
    // },
  }
})
