
import lodash from 'lodash'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import Modal from './Modal'
import { AdminTiny, Modpack, ModpackCommand, ModpackRule } from '~/types/core'
import { updatePosition } from '~/utils/misc'
import { OurProject } from '~/types/our_projects'

// noinspection JSUnusedGlobalSymbols
export default Modal.extend({
  name: 'ModpackModal',
  data () {
    return {
      cover: '',
      name: 'modpack',
      projects: [] as OurProject[],
      panel: [], // [0, 1]
      rulesModal: false
    }
  },
  created () {
    this.refresh()
  },
  watch: {
    modpackId (_: number) {
      this.refresh()
    }
  },
  methods: {
    refresh () {
      this.cover = lodash.get(this.modpack, 'banner', '') || lodash.get(this.modpack, 'cover', '') || ''
      this.loadProjects()
    },
    async loadProjects () {
      try {
        const axios = this.$axios as NuxtAxiosInstance
        const res = await axios.get<{ projects: OurProject[] }>('/minecraft/our_projects/', {
          params: {
            modpacks: `${this.modpackId}`
          }
        })
        this.projects.splice(0, this.projects.length, ...res.data.projects)
      } catch (e) {
        console.error(e)
      }
    },
    fmtBytes (size: number): string {
      const sufList = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB']
      let sufId = 0
      while (size > 1024) {
        size = Math.round(size / 102.4) / 10
        sufId++
      }
      return `${size}${this.$t('direct.bytes.' + sufList[sufId])}`
    },
    async showDownload (): Promise<void> {
      await this.$store.dispatch('modals/show', { name: 'download' })
    },
    showGameRules (): void {
      this.rulesModal = true
      updatePosition()
    },
    async showAddInfo (): Promise<void> {
      await this.$store.dispatch('modals/show', {
        name: 'PermInfoModal',
        data: this.modpackId
      })
    },
    hasRuleExtra (rule: ModpackRule): Boolean {
      return !!(rule.extra && rule.extra.length && rule.punish && rule.punish.length)
    },
    copy (text: string): void {
      const els = this.$refs['copy_' + text]
      this.$copyText(text, els ? els[0] : null).then(() => {
        this.$toast.success(this.$t('toasts.copied').toString())
      }).catch((_: any) => {
        this.$toast.error(this.$t('toasts.copyFailed').toString())
      })
    },
    cleanAdmins (admins: AdminTiny[]): AdminTiny[] {
      const adm = [...admins].filter(a => a.role.server !== null)
      adm.sort((a, b) => b.role.priority - a.role.priority)
      return adm.filter((value, index, array) => array.map(a => a.username).indexOf(value.username) === index)
    }
  },
  computed: {
    modpackId (): number {
      return this.$store.getters['modals/data']
    },
    modpack (): Modpack {
      if (this.show) {
        let modpack: Modpack
        for (modpack of this.$store.getters['core/modpacks']) {
          if (modpack.id === this.modpackId) {
            return modpack
          }
        }
      }
      return {
        add_info: null,
        admins: [],
        battle: '',
        commands: [],
        cover: null,
        banner: null,
        description: '',
        directory: '',
        id: 0,
        live: 0,
        minecraft: null,
        name: '',
        online: 0,
        price: null,
        req_cpu: '',
        req_gpu: '',
        req_gpu_mem: 0,
        req_hdd_mem: 0,
        req_os: '',
        req_ram: 0,
        req_sound: '',
        rule_sections: [],
        servers: [],
        theme: '',
        total_slots: 0,
        type: '',
        versions: [],
        wiped_at: 0,
        wiped_alt_at: '',
        world_size: ''
      }
    },
    modpackDesc (): string {
      if (!this.modpack || !('description' in this.modpack)) {
        return ''
      }
      return this.modpack.description
    },
    modpackHasRules (): Boolean {
      const modpack = this.modpack
      if ('rule_sections' in modpack) {
        for (const section of modpack.rule_sections || []) {
          if (section?.rules?.length) {
            return true
          }
        }
      }
      return false
    },
    commands () {
      const cmds: { [key: string]: ModpackCommand[] } = {}
      if (!this.modpack || !('commands' in this.modpack)) {
        return cmds
      }
      for (const cmd of this.modpack.commands) {
        const g = cmd.group ?? ''
        if (!(g in cmds)) {
          cmds[g] = []
        }
        cmds[g].push(cmd)
      }
      return cmds
    }
  }
})
