
import Vue from 'vue'
import { OurProject } from '~/types/our_projects'
import { Modpack } from '~/types/core'

export default Vue.extend({
  name: 'OurProjects',
  data () {
    return {
      loading: false,
      projects: [] as OurProject[],
      selectedModpack: 0
    }
  },
  computed: {
    modpacks (): Modpack[] {
      return this.$store.getters['core/modpacks']
    },
    modpackItems (): { text: string, value: number }[] {
      const a = [{
        text: 'Все',
        value: 0
      }]
      for (const modpack of this.modpacks) {
        a.push({
          text: `${modpack.name} ${modpack.minecraft?.version}`,
          value: modpack.id
        })
      }
      return a
    },
    modpack (): Modpack {
      for (const modpack of this.modpacks) {
        if (modpack.id === this.selectedModpack) {
          return modpack
        }
      }
      return {
        add_info: null,
        admins: [],
        battle: '',
        commands: [],
        cover: null,
        banner: null,
        description: '',
        directory: '',
        id: 0,
        live: 0,
        minecraft: null,
        name: '',
        online: 0,
        price: null,
        req_cpu: '',
        req_gpu: '',
        req_gpu_mem: 0,
        req_hdd_mem: 0,
        req_os: '',
        req_ram: 0,
        req_sound: '',
        rule_sections: [],
        servers: [],
        theme: '',
        total_slots: 0,
        type: '',
        versions: [],
        wiped_at: 0,
        wiped_alt_at: '',
        world_size: ''
      }
    }
  },
  watch: {
    selectedModpack () {
      this.loadProjects()
    }
  },
  async created () {
    await this.loadProjects()
  },
  methods: {
    async loadProjects () {
      if (this.loading) {
        return
      }
      try {
        const res = await this.$axios.get<{ projects: OurProject[] }>('/minecraft/our_projects/', {
          params: {
            modpacks: `${this.selectedModpack}`
          }
        })
        this.projects.splice(0, this.projects.length, ...res.data.projects)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  }
})
