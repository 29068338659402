
import Vue from 'vue'

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'LoaderModal',
  data () {
    return {}
  },
  computed: {
    show (): number | null {
      return this.$store.getters['loader/status']
    }
  }
})
