
import Vue from 'vue'

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'ProfileSecurity',
  middleware: 'auth',
  validate (): Promise<boolean> | boolean {
    return false
  }
})
