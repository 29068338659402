import { render, staticRenderFns } from "./ModpackModal.vue?vue&type=template&id=409a4378&scoped=true&lang=pug&"
import script from "./ModpackModal.vue?vue&type=script&lang=ts&"
export * from "./ModpackModal.vue?vue&type=script&lang=ts&"
import style0 from "./ModpackModal.vue?vue&type=style&index=0&id=409a4378&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "409a4378",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PermInfoModal: require('/app/components/PermInfoModal.vue').default})
