
import Vue from 'vue'
import type { Lootbox } from '~/types/lootboxes'
import { Modpack } from '~/types/core'
import { noWait } from '~/utils/misc'

export default Vue.extend({
  middleware: [
    async ({ store }) => {
      await store.dispatch('core/modpacksUpdate')
      await store.dispatch('lootboxes/getLootboxes')
    }
  ],
  data () {
    return {
      selectedModpack: 0,
      intervalLootboxes: 0
    }
  },
  computed: {
    lootboxes (): Lootbox[] {
      return this.$store.getters['lootboxes/lootboxes'] || []
    },
    modpacks (): Modpack[] {
      return this.$store.getters['core/modpacks']
    },
    modpackLootboxes (): Lootbox[] {
      return this.lootboxes.filter((l: Lootbox) => l.modpack_id === this.modpack?.id)
    },
    modpack (): Modpack | undefined {
      return this.modpacks.find(a => a.id === this.selectedModpack)
    },
    modpackItems (): { text: string, value: number }[] {
      const a: { text: string, value: number }[] = []
      for (const modpack of this.modpacks) {
        const count = this.lootboxes.filter((l: Lootbox) => l.modpack_id === modpack.id).length
        a.push({
          text: `${modpack.name} ${modpack.minecraft?.version} (${count})`,
          value: modpack.id
        })
      }
      return a
    }
  },
  mounted () {
    this.intervalLootboxes = window.setInterval(this.updateLootboxes, 1000 * 15)
    const modpackId = this.$route.query.modpack
    if (modpackId && typeof modpackId === 'string') {
      this.selectedModpack = parseInt(modpackId || '0')
    } else {
      this.setAvailableModpack()
    }
  },
  beforeDestroy () {
    clearInterval(this.intervalLootboxes)
  },
  methods: {
    updateLootboxes () {
      noWait(this.$store.dispatch('lootboxes/getLootboxes'))
    },
    setAvailableModpack () {
      for (const lootbox of this.$store.getters['lootboxes/lootboxes']) {
        for (const modpack of this.modpacks) {
          if (modpack.id === lootbox.modpack_id) {
            this.selectedModpack = modpack.id
            return
          }
        }
      }
    }
  }
})
