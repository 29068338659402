
import Vue from 'vue'

const numericKey = Array.from({ length: 10 }, (_v, i) => i + '')

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'CodeInput',
  model: {
    prop: 'code',
    event: 'change'
  },
  props: {
    len: {
      type: Number,
      default: 6
    },
    size: {
      type: Number,
      default: 45
    },
    gap: {
      type: Number,
      default: 6
    },
    code: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      codeArray: Array.from({ length: this.len }, () => '')
    }
  },
  computed: {
    codeString (): string {
      let code: string = ''
      for (const letter of this.codeArray) {
        code += letter || ' '
      }
      return code
    }
  },
  watch: {
    code (after, before) {
      if (after === before) {
        return
      }
      this.updateCode()
    }
  },
  created () {
    this.updateCode()
  },
  mounted () {
    this.$nextTick(() => {
      this.$nextTick(() => {
        const ref = this.ref(0)
        if (ref) {
          ref.focus()
        }
      })
    })
  },
  methods: {
    updateCode () {
      const c = this.code
      for (let i = 0; i < this.len; i++) {
        this.updateCodeArray(i, c.substring(i, i+1))
      }
    },
    handlePress (e: KeyboardEvent, index: number) {
      const ref = this.ref(index)
      const content = ref ? ref.value : null
      if (['Meta', 'Control', 'Shift'].includes(e.key)) {
        return
      } else if (e.key === 'v' && (e.ctrlKey || e.metaKey)) {
        setTimeout(() => {
          const _ref = this.ref(index)
          if (!_ref) {
            return
          }
          const val = _ref.value.match(/\d/g)
          if (!val) {
            return
          }
          for (let i = index; i < this.len; i++) {
            this.updateCodeArray(i, val.shift() || '')
            this.switchFocusAndSelect(i, 1)
          }
        }, 100)
        return
      }
      e.preventDefault()
      if (numericKey.includes(e.key)) {
        this.updateCodeArray(index, e.key)
        this.switchFocusAndSelect(index, 1)
      } else if (['ArrowRight'].includes(e.key)) {
        this.switchFocusAndSelect(index, 1)
      } else if (['ArrowLeft'].includes(e.key)) {
        this.switchFocusAndSelect(index, -1)
      } else if (['Tab'].includes(e.key)) {
        this.switchFocusAndSelect(index, e.shiftKey ? -1 : 1)
      } else if (['Backspace'].includes(e.key)) {
        if (content) {
          this.updateCodeArray(index, '')
        } else {
          this.switchFocusAndClear(index, -1)
        }
      } else if (['Delete'].includes(e.key)) {
        this.switchFocusAndClear(index, 1)
      }
    },
    updateCodeArray (index: number, value: string) {
      if (value === ' ') {
        value = ''
      }
      if (this.codeArray[index] === value) {
        return
      }
      const ref = this.ref(index)
      if (ref) {
        ref.value = value
      }
      this.codeArray.splice(index, 1, value)
      this.$emit('change', this.codeString, index)
      if (this.codeArray.every(Boolean)) {
        this.$emit('complete', this.codeString, index)
      }
    },
    switchFocusAndSelect (index: number, direction: number = 1) {
      const ref = this.ref(index + direction)
      if (ref) {
        ref.select()
      }
    },
    switchFocusAndClear (index: number, direction: number = 1) {
      const ref = this.ref(index + direction)
      if (ref) {
        ref.value = ''
        this.codeArray.splice(index + direction, 1, '')
        ref.focus()
      }
    },
    ref (index: number): HTMLInputElement | void {
      const refs = this.$refs.auth_code_input
      if (Array.isArray(refs)) {
        return refs[index] as HTMLInputElement
      }
    }
  }
})
