
import Vue from 'vue'
import { noWait } from '~/utils/misc'

export default Vue.extend({
  name: 'Home',
  layout: 'landing',
  middleware: [
    async ({ store }) => {
      // DO NOT REMOVE. It's just SSR like lol
      await store.dispatch('core/fetch')
    }
  ],
  data () {
    return {
      intervalStat: 0,
      intervalInfo: 0
    }
  },
  head (): object {
    return {
      titleTemplate: this.$t('titles.index')
    }
  },
  mounted () {
    this.intervalStat = window.setInterval(this.updateStats, 1000 * 5)
    this.intervalInfo = window.setInterval(this.updateInfo, 1000 * 60 * 5)
  },
  beforeDestroy () {
    clearInterval(this.intervalStat)
    clearInterval(this.intervalInfo)
  },
  methods: {
    updateStats () {
      noWait(this.$store.dispatch('core/monitoringUpdate'))
      noWait(this.$store.dispatch('core/modpackStatsUpdate'))
      noWait(this.$store.dispatch('core/modpacksUpdate'))
    },
    updateInfo () {
      noWait(this.$store.dispatch('core/bannerNewsUpdate'))
    }
  }
})
