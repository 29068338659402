
import Vue from 'vue'
import _ from 'lodash'
import { Modpack, ModpackStat } from '~/types/core'

export default Vue.extend({
  name: 'Modpack',
  data () {
    return {
      covers: {}
    }
  },
  computed: {
    modpacks (): Modpack[] {
      return this.$store.getters['core/modpacks']
    }
  },
  watch: {
    modpacks (val: Modpack[]) {
      this.updateCovers(val)
    }
  },
  created () {
    this.updateCovers(this.modpacks)
  },
  methods: {
    updateCovers (val: Modpack[]) {
      for (const modpack of val) {
        if (modpack.id in this.covers) {
          continue
        }
        this.covers[modpack.id] = modpack.cover
      }
    },
    showModpack (modpack: Modpack) {
      this.$store.dispatch('modals/show', {
        name: 'modpack',
        data: modpack.id
      }).then().catch(console.error)
    },
    stat (modpack: Modpack): ModpackStat {
      const def: ModpackStat = {
        online: 0,
        total_slots: 0
      }
      if (_.isEmpty(modpack)) {
        return def
      }
      const stats = this.$store.getters['core/modpackStats']
      if (_.isEmpty(stats) || !_.has(stats, modpack.id)) {
        return def
      }
      return stats[modpack.id]
    }
  }
})
