
import Vue from 'vue'
import converter from 'discord-emoji-converter/dist/discord-emoji-converter.min'
import unicodeSubstring from 'unicode-substring'
import { Guide, GuideCategory } from '~/types/guides'
import { Modpack } from '~/types/core'
import { sleep } from '~/utils/misc'

type Heading = {
  name: string
  slug: string
  level: 1 | 2 | 3 | 4 | 5 | 6
}

const defaultGuide = {
  title: 'Missing title',
  slug: 'missing-title-00',
  content: '# Content is missing',
  banner: null,
  categories: [],
  keywords: '',
  modpacks: [],
  created_at: '2023-01-01T12:00:00.000000Z',
  updated_at: '2023-01-01T12:00:00.000000Z'
}

export default Vue.extend({
  name: 'PageGuide',
  layout: 'landing',
  middleware: [
    async ({ store }) => {
      await store.dispatch('core/modpacksUpdate')
    }
  ],
  async asyncData ({
    $axios,
    $marked,
    params
  }) {
    let guideCategories: GuideCategory[] = []
    let guide: Guide = defaultGuide
    try {
      const res = await $axios.get<{ categories: GuideCategory[] }>('/minecraft/guides/categories')
      guideCategories = res.data.categories
    } catch (e) {
      console.error(e)
    }
    const guideSlug = params.slug
    try {
      const res = await $axios.get<{ guide: Guide }>(`/minecraft/guides/guide/${guideSlug}`)
      guide = res.data.guide
    } catch (e) {
      console.error(e)
    }
    const content = await $marked.parse(guide.content, { async: true })
    return {
      guideCategories,
      guideSlug,
      guide,
      content
    }
  },
  data () {
    return {
      guideSlug: '',
      guide: defaultGuide,
      guideCategories: [] as GuideCategory[],
      search: '',
      showModpacks: true,
      markdownHTML: '',
      searchResetDelay: 0,
      activeAnchor: '',
      activeHeading: null as Heading | null,
      content: '',
      headings: [] as Heading[],
      headingSizes: {} as Record<string, number>
    }
  },
  computed: {
    modpacks (): Modpack[] {
      return this.$store.getters['core/modpacks']
    },
    modpackIds (): number[] {
      return this.modpacks.map(m => m.id)
    },
    contentBarStyle () {
      let y = 7
      const h = 30
      if (!this.activeHeading) {
        return {
          '--y': '7px',
          '--h': '30px',
          '--o': '0'
        }
      }
      // h = ys[this.activeHeading.level]
      for (const heading of this.headings) {
        const ys = this.headingSizes[heading.slug] || 0
        if (heading.slug === this.activeHeading.slug) {
          if (ys > 30) {
            y += (ys - 30) / 2
          }
          break
        }
        y += ys
      }
      return {
        '--y': y + 'px',
        '--h': h + 'px',
        '--o': '1'
      }
    }
  },
  watch: {
    async guide () {
      this.content = await this.$marked.parse(this.guide.content, { async: true })
      await this.updateHeadings()
    }
  },
  async mounted () {
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('scroll', this.updateActiveAnchor)
    await sleep(100)
    await this.updateHeadings()
    await sleep(1)
    this.onScroll()
    this.updateActiveAnchor()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('scroll', this.updateActiveAnchor)
  },
  methods: {
    async share () {
      const url = new URL(document.location.href)
      url.hash = ''
      await this.$copyText(url.href)
      await this.$toast.success('Copied to clipboard')
    },
    onScroll () {
      if (!this.$refs.viewer) {
        return
      }
      const viewer = this.$refs.viewer as Vue
      const headers = viewer.$el.querySelectorAll('h1,h2,h3,h4,h5,h6')
      this.activeHeading = null
      if (headers.length !== this.headings.length) {
        return
      }
      for (let i = 0; i < headers.length; i++) {
        const header = headers[i]
        const heading = this.headings[i]
        if (heading.level > 2) {
          continue
        }
        const { y } = header.getBoundingClientRect()
        if (y <= 140 || !this.activeHeading) {
          this.activeHeading = heading
        }
      }
    },
    async updateHeadings () {
      await sleep(1)
      if (!this.$refs.viewer) {
        return
      }
      const viewer = this.$refs.viewer as Vue
      this.headingSizes = {}
      this.headings.splice(0, this.headings.length)
      viewer.$el.querySelectorAll('h1,h2,h3,h4,h5,h6').forEach((el) => {
        this.headings.push({
          name: el.textContent || '',
          level: parseInt(el.tagName[1]) as (1 | 2 | 3 | 4 | 5 | 6),
          slug: el.id
        })
      })
      await sleep(1)
      document.querySelectorAll('._heading').forEach((el) => {
        const a = el.firstElementChild as HTMLAnchorElement | null
        if (!a) {
          return
        }
        const slug = a.attributes.getNamedItem('href')?.value?.substring(1)
        if (slug) {
          this.headingSizes[slug] = el.getBoundingClientRect().height
        }
      })
    },
    updateActiveAnchor () {
      const hash = this.$route.hash
      if (!hash) {
        this.activeAnchor = ''
        return
      }
      for (const heading of this.headings) {
        if (`#${encodeURIComponent(heading.slug)}` === hash) {
          this.activeAnchor = heading.slug
          return
        }
      }
    },
    async loadGuide () {
      try {
        const res = await this.$axios.get<{ guide: Guide }>(`/guides/guide/${this.guideSlug}`)
        this.guide = res.data.guide
      } catch (e) {
        console.error(e)
      }
    },
    async selectSearch () {
      await this.$router.push({
        path: this.localePath('/guides'),
        query: { search: this.search }
      })
    },
    async selectCategory (cat: number) {
      await this.$router.push({
        path: this.localePath('/guides'),
        query: { cat: `${cat}` }
      })
    },
    async selectModpack (modpack: number) {
      await this.$router.push({
        path: this.localePath('/guides'),
        query: { modpacks: `${modpack}` }
      })
    },
    getEmoji: converter.getEmoji,
    unicodeSubstring
  }
})
