import { render, staticRenderFns } from "./_slug.vue?vue&type=template&id=77e92c4e&scoped=true&lang=pug&"
import script from "./_slug.vue?vue&type=script&lang=ts&"
export * from "./_slug.vue?vue&type=script&lang=ts&"
import style0 from "./_slug.vue?vue&type=style&index=0&id=77e92c4e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77e92c4e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MarkdownView: require('/app/components/MarkdownView.vue').default})
