import { render, staticRenderFns } from "./index.vue?vue&type=template&id=72003172&lang=pug&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingHero: require('/app/components/landing/Hero.vue').default,LandingBonus: require('/app/components/landing/Bonus.vue').default,LandingBannerNews: require('/app/components/landing/BannerNews.vue').default,LandingModpack: require('/app/components/landing/Modpack.vue').default,LandingOurProjects: require('/app/components/landing/OurProjects.vue').default,LandingDonate: require('/app/components/landing/Donate.vue').default,LandingLootboxes: require('/app/components/landing/Lootboxes.vue').default,LandingGuides: require('/app/components/landing/Guides.vue').default,LandingAdminApply: require('/app/components/landing/AdminApply.vue').default})
