
import Vue from 'vue'

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'Payments',
  head (): object {
    return {
      titleTemplate: '%s | ' + this.$t('documents.payments')
    }
  }
})
