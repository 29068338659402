
import Vue from 'vue'
import ListNav from '~/components/ListNav.vue'

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'Profile',
  components: { ListNav },
  data () {
    return {}
  },
  head (): object {
    return {
      titleTemplate: this.$t('titles.documents')
    }
  }
})
