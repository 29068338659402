
import Modal from '~/components/Modal'
import { Modpack, ModpackRule } from '~/types/core'

export default Modal.extend({
  name: 'ModpackRulesModal',
  data () {
    return {
      name: 'modpackRules',
      selectedModpack: 0
    }
  },
  created () {
    this.refresh()
  },
  methods: {
    refresh () {
      const modpackId = this.modpackId
      if (modpackId > 0) {
        this.selectedModpack = modpackId
      } else {
        for (const modpack of this.$store.getters['core/modpacks']) {
          this.selectedModpack = modpack.id
        }
      }
    },
    hasRuleExtra (rule: ModpackRule): Boolean {
      return !!(rule.extra && rule.extra.length && rule.punish && rule.punish.length)
    }
  },
  computed: {
    modpackId (): number {
      return this.$store.getters['modals/data'] || 0
    },
    modpack (): Modpack {
      if (this.show) {
        for (const modpack of this.modpacks) {
          if (modpack.id === this.selectedModpack) {
            return modpack
          }
        }
      }
      return {
        add_info: null,
        admins: [],
        battle: '',
        commands: [],
        cover: null,
        banner: null,
        description: '',
        directory: '',
        id: 0,
        live: 0,
        minecraft: null,
        name: '',
        online: 0,
        price: null,
        req_cpu: '',
        req_gpu: '',
        req_gpu_mem: 0,
        req_hdd_mem: 0,
        req_os: '',
        req_ram: 0,
        req_sound: '',
        rule_sections: [],
        servers: [],
        theme: '',
        total_slots: 0,
        type: '',
        versions: [],
        wiped_at: 0,
        wiped_alt_at: '',
        world_size: ''
      }
    },
    modpackHasRules (): boolean {
      const modpack = this.modpack
      if ('rule_sections' in modpack) {
        for (const section of modpack.rule_sections || []) {
          if (section?.rules?.length) {
            return true
          }
        }
      }
      return false
    },
    modpacks (): Modpack[] {
      return this.$store.getters['core/modpacks']
    },
    modpackItems (): Record<string, unknown>[] {
      const a: Record<string, unknown>[] = []
      for (const modpack of this.modpacks) {
        a.push({
          text: `${modpack.name} ${modpack.minecraft?.version}`,
          value: modpack.id
        })
      }
      return a
    }
  }
})
