import { render, staticRenderFns } from "./Modpack.vue?vue&type=template&id=475e720b&lang=pug&"
import script from "./Modpack.vue?vue&type=script&lang=ts&"
export * from "./Modpack.vue?vue&type=script&lang=ts&"
import style0 from "./Modpack.vue?vue&type=style&index=0&id=475e720b&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModpackModal: require('/app/components/ModpackModal.vue').default})
