
import Vue from 'vue'
import _ from 'lodash'
import { AxiosResponse } from 'axios'
import { MCUser } from '~/types/user'
import { BooleanRule, NumberRule, StrDict, TextRule } from '~/types'
import { Payment } from '~/types/core'
import { get_error_message } from '~/utils/misc'

type PaymentType =
  | 'bank_russia'
  | 'bank_ukraine'
  | 'bank_foreign'
  | 'qiwi'
  | 'steam'
  | 'crypto';

type UnitPayType = 'domestic' | 'foreign'
type FreekassaType = 'freekassa'
type RedirectStatus = 'success' | 'failure'

interface DonateOptions {
  amount: number
}

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'Donate',
  layout: 'profile',
  middleware: [
    async ({ store }) => {
      await store.dispatch('core/paymentUpdate')
    }
  ],
  data () {
    return {
      paymentType: 'bank_russia' as PaymentType,
      form: {
        valid: false,
        username: '',
        amount: '',
        type: 'domestic' as UnitPayType | FreekassaType,
        agree: false
      },
      status: null as RedirectStatus | null
    }
  },
  head () {
    return {
      titleTemplate: this.$t('titles.donate').toString(),
      script: [
        {
          src: 'https://widget.unitpay.ru/unitpay.js',
          async: true
        }
      ]
    }
  },
  computed: {
    mcUser (): MCUser {
      return this.$store.getters['core/mcUser'] as MCUser
    },
    isAuthorized (): Boolean {
      return !_.isEmpty(this.mcUser)
    },
    donate (): DonateOptions {
      return this.$store.getters['modals/data']
    },
    payment (): Payment {
      return this.$store.getters['core/payment']
    },
    gold (): number {
      const gold = Math.max(parseInt(this.form.amount), 0)
      return _.isNaN(gold) ? 0 : gold
    },
    discounts (): [number, number][] {
      return this.payment.discounts
    },
    discount (): [number, number] {
      const gold = this.gold
      let result: [number, number] = [0, 0]
      for (const discount of this.discounts) {
        if (gold >= discount[0] && result[1] < discount[1]) {
          result = discount
        }
      }
      return result
    },
    willReceive (): number {
      const rec = this.gold * (1 + this.discount[1] / 100)
      return Math.floor(rec)
    },
    usernameRules (): TextRule[] {
      return [
        (v: string) => !!v || this.$t('pages.donate.rules.username.empty'),
        (v: string) => v.length >= 3 || this.$t('pages.donate.rules.username.short'),
        (v: string) => v.length <= 16 || this.$t('pages.donate.rules.username.long'),
        (v: string) => !v.search(/^[a-zA-Zа-яА-ЯёË\d\-_]/) || this.$t('pages.donate.rules.username.malformed1'),
        (v: string) => !v.search(/^[a-zA-Zа-яА-ЯёË\d\-_][a-zA-Zа-яА-ЯёË\d\-_.]{1,22}[a-zA-Zа-яА-ЯёË\d\-_]$/) || this.$t('pages.donate.rules.username.malformed2')
      ]
    },
    amountRules (): NumberRule[] {
      return [
        v => !!v || this.$t('pages.donate.rules.amount.empty'),
        v => v >= 10 || this.$t('pages.donate.rules.amount.tiny'),
        v => v <= 50000 || this.$t('pages.donate.rules.amount.huge')
      ]
    },
    agreeRules (): BooleanRule[] {
      return [
        v => v || this.$t('pages.donate.rules.agree.false')
      ]
    },
    progressUsernameValue (): number {
      return Math.min(100, 100 * this.form.username.length / 16)
    },
    amountText (): string {
      if (this.form.amount === '') {
        return ''
      }
      return `${this.form.amount} ₽`
    }
  },
  watch: {
    paymentType () {
      if (['bank_russia'].includes(this.paymentType)) {
        this.form.type = 'domestic'
      } else if (['bank_ukraine', 'bank_foreign'].includes(this.paymentType)) {
        this.form.type = 'foreign'
      } else {
        this.form.type = 'freekassa'
      }
    }
  },
  async beforeMount () {
    await this.init()
  },
  async created () {
    await this.init()
  },
  methods: {
    async init () {
      if ('success' in this.$route.query) {
        this.status = 'success'
      } else if ('failure' in this.$route.query) {
        this.status = 'failure'
      }
      await this.loadMCUser()
      if (this.isAuthorized) {
        this.form.username = this.mcUser.username
        if (typeof document === 'undefined') {
          return
        }
        const el = document.querySelector('#amountField') as HTMLInputElement
        if (el) {
          el.focus()
        }
      }
    },
    async loadMCUser () {
      if (!this.$auth.loggedIn) {
        return
      }
      try {
        await this.$store.dispatch('core/fetchMinecraftUser')
      } catch (e) {
        console.error(e)
      }
    },
    onGoldInput (e: KeyboardEvent) {
      if (!e.code.match(/Digit\d/)) {
        return
      }
      if (this.form.amount.toString().length > 4 && window.getSelection()?.type !== 'Range') {
        e.preventDefault()
      }
    },
    async initPayment () {
      if (!this.form.valid) {
        return
      }
      const w = window as Window
      let response: AxiosResponse
      try {
        response = await this.$axios.post('/minecraft/donate', {
          username: this.form.username,
          amount: this.gold,
          type: this.form.type
        })
      } catch (err: any) {
        await this.$makeSad({
          text: this.$_(get_error_message(err)),
          title: 'Ошибка пополнения баланса'
        })
        return false
      }
      if (['domestic', 'foreign'].includes(this.form.type)) {
        if (!('UnitPay' in w)) {
          await this.$makeSad({
            text: this.$_('Не удалось загрузить виджет оплаты'),
            title: 'Ошибка загрузки'
          })
          return false
        }
        // @ts-ignore
        const payment = new w.UnitPay()
        payment.createWidget({
          publicKey: this.form.type === 'domestic' ? this.payment.key : this.payment.foreign_key,
          sum: parseFloat(response.data.orderSum),
          currency: response.data.currency,
          account: response.data.account,
          domainName: 'unitpay.ru',
          signature: response.data.sig,
          desc: response.data.desc,
          locale: 'ru'
        })
        payment.success(async () => {
          await Promise.all([this.loadMCUser(), this.$makeHappy()])
        })
        payment.error(async (message: string, params: StrDict) => {
          console.error({
            message,
            params
          })
          await this.$makeSad({
            text: this.$_(message),
            title: 'Ошибка пополнения баланса'
          })
        })
        return false
      } else if (['freekassa'].includes(this.form.type)) {
        const u = new URL('https://pay.freekassa.ru/')
        u.searchParams.set('m', this.payment.freekassa_id)
        u.searchParams.set('oa', response.data.orderSum)
        u.searchParams.set('currency', response.data.currency)
        u.searchParams.set('o', response.data.account)
        u.searchParams.set('s', response.data.sig)
        u.searchParams.set('lang', 'ru')
        document.location.href = u.toString()
      }
    }
  }
})
