import { render, staticRenderFns } from "./landing.vue?vue&type=template&id=1570dcb7&lang=pug&"
import script from "./landing.vue?vue&type=script&lang=ts&"
export * from "./landing.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoaderModal: require('/app/components/LoaderModal.vue').default,DownloadModal: require('/app/components/DownloadModal.vue').default,HelpModal: require('/app/components/HelpModal.vue').default,Alerts: require('/app/components/Alerts.vue').default,LandingNav: require('/app/components/LandingNav.vue').default,SiteFooter: require('/app/components/SiteFooter.vue').default})
