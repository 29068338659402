
/* eslint-disable dot-notation */
import _ from 'lodash'
import Vue from 'vue'
import { StrDict } from '~/types'

interface D {
  viewer: StrDict
  controller: StrDict
  walk: StrDict
  rotate: StrDict
  defaultSkin: string
}

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'Steve',
  props: {
    skinUrl: {
      type: String,
      default: null,
      required: false
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 600
    },
    fluid: {
      type: Boolean,
      default: true
    }
  },
  data (): D {
    return {
      viewer: {},
      controller: {},
      walk: {},
      rotate: {},
      defaultSkin: '/img/steve.png'
    }
  },
  computed: {
    canvas (): HTMLCanvasElement {
      return this.$refs.canvas as HTMLCanvasElement
    }
  },
  watch: {
    skinUrl () {
      this.loadSkin().then()
    },
    width () {
      if (!_.isEmpty(this.viewer)) {
        this.viewer.width = this.width
      }
    },
    height () {
      if (!_.isEmpty(this.viewer)) {
        this.viewer.height = this.height
      }
    },
    fluid () {
      this.setStyle()
    }
  },
  created () {
    // On the next event loop iteration
    this.$nextTick(async () => {
      // Set up skin viewer
      this.viewer = new this.$steve.SkinViewer({
        canvas: this.canvas,
        width: this.width,
        height: this.height
      })
      await this.loadSkin()
      // @ts-ignore
      this.controller = this.$steve.createOrbitControls(this.viewer)
      this.controller.enableRotate = true
      this.controller.enableZoom = false
      this.controller.enablePan = false
      this.walk = this.viewer.animations.add(this.$steve.WalkingAnimation)
      this.rotate = this.viewer.animations.add(this.$steve.RotatingAnimation)
      this.walk.speed = 0.5
      this.rotate.speed = 0.5
      this.setStyle()
    })
  },
  methods: {
    async loadSkin () {
      // If skinUrl is provided
      try {
        // Try to download it to memory
        const res: Response = await fetch(this.skinUrl || this.defaultSkin, {
          credentials: 'omit',
          redirect: 'follow'
        })
        // Save image as BLOB and use its path
        await this.viewer.loadSkin(URL.createObjectURL(await res.blob()))
      } catch (e: any) {
        // If something happens
        console.warn('Failed to load skin because of', e)
        // Fallback to default skin (stored as static)
        await this.viewer.loadSkin(this.defaultSkin)
        // And warn user with toast that skin is failed to download
        this.$toasted.show(this.$t('toasts.skinLoadError') as string, {
          icon: 'exit',
          type: 'error'
        })
      }
    },
    setStyle () {
      const c = this.canvas
      if (c) {
        try {
          // Resize skin to provided dimensions or to fill the box
          c.style.width = this.fluid ? '100%' : `${this.width}px`
          c.style.height = this.fluid ? '100%' : `${this.height}px`
          // Remove element outline
          c.style.outline = 'none'
        } catch (e: any) {
        }
      }
    }
  }
})
