
import Vue from 'vue'

export default Vue.extend({
  name: 'Documents',
  data () {
    return {
      documents: [
        // 'tos',
        'confidentiality',
        'data_handle',
        'eula',
        'international_payments',
        'itemStorage',
        'minecraft_rules',
        'payments',
        'requisites',
        'return_policy',
        'support'
      ]
    }
  }
})
