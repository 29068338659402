
import Vue from 'vue'

export default Vue.extend({
  name: 'ProfileTheme',
  middleware: 'auth',
  data () {
    return {}
  },
  methods: {
    setTheme (mode: 'dark' | 'light' | 'system'): Promise<void> {
      return this.$store.dispatch('core/setTheme', mode)
    }
  }
})
