
import Vue from 'vue'

export default Vue.extend({
  data () {
    return {
      applyLink: 'https://docs.google.com/forms/d/e/1FAIpQLSd2zft3361k_1YgUm867phTxCW0aAXGRFtOcXrTmqjJTEjimA/viewform'
    }
  }
})
