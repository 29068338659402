
import Vue from 'vue'

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'ListNav',
  props: {
    exit: {
      type: Boolean,
      default: true
    },
    size: {
      type: Number,
      default: 14
    }
  },
  data () {
    return {}
  },
  computed: {
    textStyle (): string {
      return '' +
          `margin-left: ${this.size / 2}px;` +
          `font-size: ${this.size}px`
    }
  },
  methods: {}
})
