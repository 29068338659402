
import Vue from 'vue'
import LoaderModal from '~/components/LoaderModal.vue'
import Alerts from '~/components/Alerts.vue'

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'FormLayout',
  components: {
    Alerts,
    LoaderModal
  },
  data: () => ({}),
  head () {
    return {
      meta: [{
        name: 'theme-color',
        content: (this.$vuetify.theme.currentTheme?.background || '#121214') as string
      }]
    }
  },
  computed: {
    logo (): string {
      return '/img/cifrazia' + (this.$vuetify.theme.dark ? '' : '-black') + '.svg'
    }
  }
})
