
import Vue from 'vue'
import { Guide } from '~/types/guides'

export default Vue.extend({
  name: 'Guides',
  data () {
    return {
      loading: false,
      guides: [] as Guide[]
    }
  },
  async created () {
    await this.loadGuides()
  },
  methods: {
    async loadGuides () {
      if (this.loading) {
        return
      }
      try {
        const res = await this.$axios.get<{ guides: Guide[] }>('/minecraft/guides/')
        this.guides.splice(0, this.guides.length, ...res.data.guides)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  }
})
