
import Modal from './Modal'

// noinspection JSUnusedGlobalSymbols
export default Modal.extend({
  name: 'NeedAuthModal',
  data () {
    return {
      name: 'needAuth'
    }
  }
})
