
import Vue from 'vue'

export default Vue.extend({
  name: 'EmptyNav',
  data: () => ({
    profileDropDown: false
  }),
  head () {
    return {
      meta: [{
        name: 'theme-color',
        content: (this.$vuetify.theme.currentTheme?.card || '#121214') as string
      }]
    }
  },
  computed: {
    logo (): string {
      return '/img/icons/logo' + (this.$vuetify.theme.dark ? '' : '-black') + '.svg'
    },
    cifrazia (): string {
      return '/img/cifrazia-colored.svg'
      // return '/img/cifrazia' + (this.$vuetify.theme.dark ? '' : '-black') + '.svg'
    }
  },
  methods: {
    async logout () {
      await this.$auth.logout()
    },
    showDownload () {
      this.$store.dispatch('modals/show', { name: 'download' }).then().catch(console.error)
    }
  }
})
