
import Vue from 'vue'
import { BannerNewsItem } from '~/types/core'

export default Vue.extend({
  name: 'BannerNews',
  data () {
    return {
      newsSlider: {
        pos: 0,
        moving: false,
        options: {
          type: 'slider',
          perView: 2,
          gap: 24
          // dragThreshold: false
        }
      }
    }
  },
  computed: {
    newsSliderPrev (): boolean {
      return this.newsSlider.pos > 0
    },
    newsSliderNext (): boolean {
      return this.news.length > this.newsSlider.pos + this.newsSlider.options.perView
    },
    news (): BannerNewsItem[] {
      return this.$store.getters['core/bannerNews']
    }
  },
  methods: {
    newsSliderGoNext () {
      if (!this.newsSlider.moving) {
        this.newsSlider.pos += this.newsSlider.options.perView
        this.newsSlider.moving = true
      }
    },
    newsSliderGoPrev () {
      if (!this.newsSlider.moving) {
        this.newsSlider.pos -= this.newsSlider.options.perView
        this.newsSlider.moving = true
      }
    }
  }
})
