
import Vue from 'vue'
import { get_error_message, hashObject, noWait } from '~/utils/misc'
import CodeInput from '~/components/CodeInput.vue'

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'RestoreAccessCheck',
  components: { CodeInput },
  layout: 'form',
  middleware: 'auth',
  auth: 'guest',
  data () {
    return {
      form: {
        value: false,
        email: '',
        token: '',
        code: ''
      }
    }
  },
  head (): object {
    return {
      titleTemplate: this.$t('titles.restoreAccess')
    }
  },
  created () {
    const redirectTo = this.$route.query['redirect-to']
    if (typeof redirectTo !== 'undefined') {
      this.$cookies.set('redirectTo', redirectTo)
    }

    this.$nextTick(() => {
      const hash = hashObject()
      if ('token' in hash && !hash.token.search(/^[a-zA-Z\d]{32,}$/)) {
        this.form.token = hash.token
        if ('code' in hash && !hash.code.search(/^\d{6}$/)) {
          this.form.code = hash.code
        }
      }
      if ('email' in hash) {
        this.form.email = hash.email
      }
    })
  },
  methods: {
    async onSubmitCode (): Promise<void> {
      if (!this.form.token || !this.form.code) {
        noWait(this.$makeAware({ timeout: 1500 }))
        return
      }
      try {
        const res = await this.$loader(this.restoreCheck(), {
          timeout: 0,
          immediate: true
        })
        if (res) {
          console.debug('Confirmation succeeded')
          this.$go(`/restore-access-confirm#token=${res.token}`)
        }
      } catch (e: any) {
        noWait(this.$makeSad({ text: this.$_(get_error_message(e)) }))
      }
      this.form.code = ''
    },
    async restoreCheck (): Promise<false | { token: string }> {
      if (this.$auth.loggedIn) {
        await this.$makeAware({
          text: 'Already authorized',
          timeout: 3000
        })
        this.$go('/')
        return false
      }
      const res = await this.$axios.post(
        '/me/auth/restore-access/check',
        {
          code: this.form.code,
          token: this.form.token
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      return res.data
    }
  }
})
