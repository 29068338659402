
import Vue from 'vue'

export default Vue.extend({
  name: 'Donate',
  methods: {
    showPermInfo () {
      this.$store.dispatch('modals/show', {
        name: 'PermInfoModal',
        data: null
      }).then().catch(console.error)
    }
  }
})
