
import Vue from 'vue'

export default Vue.extend({
  props: {
    x: {
      default: 300,
      type: Number
    },
    y: {
      default: 300,
      type: Number
    },
    z: {
      default: 300,
      type: Number
    },
    side: {
      default: 'front',
      type: String
    },
    rx: {
      default: null,
      type: String
    },
    ry: {
      default: null,
      type: String
    },
    tz: {
      default: null,
      type: String
    }
  },
  computed: {
    boxTransform () {
      let rotateX = '0deg'
      let rotateY = '0deg'
      let translateZ = '-50deg'
      if (this.side === 'back') {
        [translateZ, rotateY] = [`-${this.z as number / 2}px`, '-180deg']
      } else if (this.side === 'right') {
        [translateZ, rotateY] = [`-${this.x as number / 2}px`, '-90deg']
      } else if (this.side === 'left') {
        [translateZ, rotateY] = [`-${this.x as number / 2}px`, '90deg']
      } else if (this.side === 'top') {
        [translateZ, rotateX] = [`-${this.y as number / 2}px`, '-90deg']
      } else if (this.side === 'bottom') {
        [translateZ, rotateX] = [`-${this.y as number / 2}px`, '90deg']
      } else {
        [translateZ, rotateY] = [`-${this.z as number / 2}px`, '0deg']
      }
      if (this.tz != null) {
        translateZ = this.tz as string
      }
      if (this.rx != null) {
        rotateX = this.rx as string
      }
      if (this.ry != null) {
        rotateY = this.ry as string
      }
      return { transform: `translateZ(${translateZ}) rotateX(${rotateX}) rotateY(${rotateY})` }
    },
    styleFaceFront () {
      return {
        width: this.x + 'px',
        height: this.y + 'px',
        transform: `rotateY(0deg) translateZ(${this.z as number / 2}px)`
      }
    },
    styleFaceBack () {
      return {
        width: this.x + 'px',
        height: this.y + 'px',
        transform: `rotateY(180deg) translateZ(${this.z as number / 2}px)`
      }
    },
    styleFaceLeft () {
      return {
        width: this.z + 'px',
        height: this.y + 'px',
        transform: `rotateY(-90deg) translateZ(${this.z as number / 2}px)`
      }
    },
    styleFaceRight () {
      return {
        width: this.z + 'px',
        height: this.y + 'px',
        transform: `rotateY(90deg) translateZ(${(this.x as number) - (this.z as number) / 2}px)`
      }
    },
    styleFaceTop () {
      return {
        width: this.x + 'px',
        height: this.z + 'px',
        transform: `rotateX(90deg) translateZ(${this.z as number / 2}px)`
      }
    },
    styleFaceBottom () {
      return {
        width: this.x + 'px',
        height: this.z + 'px',
        transform: `rotateX(-90deg) translateZ(${(this.y as number) - (this.z as number) / 2}px)`
      }
    }
  }
})
