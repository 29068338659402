
import Vue from 'vue'

export default Vue.extend({
  name: 'ErrorLayout',
  layout: 'form',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data () {
    return {}
  },
  head (): object {
    const title =
      this.error.statusCode === 404 ? '404 не найдено' : 'Ошибочка вышла'
    return {
      title,
      meta: [{
        name: 'theme-color',
        content: (this.$vuetify.theme.currentTheme?.background || '#121214') as string
      }]
    }
  },
  methods: {
    goBack () {
      this.$router.back()
    }
  }
})
