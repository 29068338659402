
import Vue from 'vue'
import { LocaleObject } from '@nuxtjs/i18n/types'

export default Vue.extend({
  name: 'ProfileCommon',
  middleware: 'auth',
  data () {
    return {
      username: '',
      usernameEdit: false,
      usernameValid: false,
      password: '',
      passwordRepeat: '',
      passwordEdit: false,
      login: '',
      emailEdit: false,
      flags: {
        ru: '🇷🇺',
        en: '🇺🇸',
        de: '🇩🇪',
        it: '🇮🇹',
        ch: '🇨🇳',
        es: '🇪🇸',
        fr: '🇫🇷'
      }
    }
  },
  computed: {
    timeZone (): string {
      return this.$store.getters['core/timeZone']
    },
    usernameRules (): Array<(v: string) => boolean | string> {
      return [
        (v: string) => !!v || 'Пожалуйста введите никнейм',
        (v: string) => v.length >= 3 || 'Слишком кородкий никнейм (мин. 3)',
        (v: string) => v.length <= 16 || 'Слишком длинный никнейм (макс. 16)',
        (v: string) => !v.search(/^[a-zA-Z\d]/) || 'Никнейм должен начинаться с буквы или цифры',
        (v: string) => !v.search(/^[a-zA-Z\d][a-zA-Z\d\-_]{2,15}$/) || 'Никнейм содержит недопустимые символы'
      ]
    },
    availableLocales (): LocaleObject[] {
      return this.$i18n.locales as LocaleObject[]
    },
    locale (): string {
      const cur = this.$i18n.locale
      for (const locale of this.availableLocales) {
        if (locale.code === cur) {
          return locale.name
        }
      }
      return 'Русский'
    },
    timeZones (): { text: string, value: string }[] {
      const zones: { text: string, value: string }[] = []
      for (let i = -12; i <= 12; i++) {
        const z = `GMT+${i}`.replace('+-', '-')
        zones.push({
          text: z,
          value: 'Etc/' + z
        })
      }
      zones.splice(12, 1, {
        text: 'UTC',
        value: 'UTC'
      })
      return zones
    }
  },
  methods: {
    async usernameSave (): Promise<void> {
      await this.$makeHappy({ text: 'SAVED' })
      this.usernameEdit = false
    },
    localeSave (locale: string): void {
      this.$i18n.setLocaleCookie(locale)
      document.location.href = this.switchLocalePath(locale)
    },
    timeZoneSave (tz: string): void {
      this.$store.dispatch('core/setTimeZone', tz)
      this.$toasted.show(this.$t('toasts.saved') as string, {
        icon: 'check',
        type: 'success'
      })
    }
  }
})
