import { render, staticRenderFns } from "./lootbox.vue?vue&type=template&id=28943dac&scoped=true&lang=pug&"
import script from "./lootbox.vue?vue&type=script&lang=ts&"
export * from "./lootbox.vue?vue&type=script&lang=ts&"
import style0 from "./lootbox.vue?vue&type=style&index=0&id=28943dac&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28943dac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LootboxPrize: require('/app/components/lootbox/lootbox-prize.vue').default})
