
import type { PropType } from 'vue'
import Vue from 'vue'
import { RecordPrize } from '~/types/lootboxes'

export default Vue.extend({
  props: {
    prize: {
      type: Object as PropType<RecordPrize | null>,
      default: null
    },
    rounded: {
      type: Boolean
    },
    tooltip: {
      type: Boolean
    },
    size: {
      type: Number,
      default: 64
    }
  },
  data () {
    return {
      rarities: {
        unknown: '???',
        common: 'COMMON',
        rare: 'RARE',
        epic: 'EPIC',
        legendary: 'ARCANE'
      },
      defaultIcon: '/img/lootboxes/gold_coin.svg',
      timer: 0 as number,
      showTooltip: false,
      left: 0,
      top: 0
    }
  },
  computed: {
    rarity (): string {
      return this.prize?.rarity || 'unknown'
    },
    icon (): string {
      return this.prize?.icon || this.defaultIcon
    },
    name (): string {
      return this.prize?.name || ''
    },
    amount (): number {
      const prize = this.prize
      if (!prize) {
        return 0
      }
      if (prize.prize_type === 'currency') {
        return prize.meta.amount
      }
      if (prize.prize_type === 'role') {
        return prize.meta.months
      }
      if (prize.prize_type === 'item') {
        return prize.meta.count
      }
      return 0
    }
  },
  methods: {
    mouseMove (e: MouseEvent) {
      e.preventDefault()
      const ref = this.$refs.prizeRef as Element
      if (!ref) {
        return
      }
      const b = ref.getBoundingClientRect()
      this.left = e.clientX - b.x - 1
      this.top = e.clientY - b.y - 1
    },
    mouseEnter (e: MouseEvent) {
      e.preventDefault()
      this.timer = window.setTimeout(() => {
        this.showTooltip = true
      }, 400)
    },
    mouseLeave (e: MouseEvent) {
      e.preventDefault()
      clearTimeout(this.timer)
      this.showTooltip = false
    }
  }
})
