
import Vue from 'vue'
import { AxiosResponse } from 'axios'
import { get_error_message, noWait } from '~/utils/misc'

export default Vue.extend({
  name: 'SignIn',
  layout: 'form',
  middleware: 'auth',
  auth: 'guest',
  data () {
    return {
      step: 0,
      form: {
        value: false,
        login: '',
        password: '',
        remember: true,
        token: '',
        code: ''
      },
      otpMessage: {
        email: '',
        type: 'unknown'
      },
      showPassword: false,
      loginRules: [
        (v: string) => !!v || this.$t('pages.signIn.rules.login.empty'),
        (v: string) => v.length >= 3 || this.$t('pages.signIn.rules.login.short'),
        (v: string) => {
          if (v.length <= 24 && v.match(/^[a-zA-Zа-яА-ЯёË\d\-_][a-zA-Zа-яА-ЯёË\d\-_.]{1,22}[a-zA-Zа-яА-ЯёË\d\-_]$/)) {
            return true
          } else if (v.length <= 254) {
            const at = v.indexOf('@')
            if (at >= 1 && at < v.length) {
              return true
            } else {
              return this.$t('pages.signIn.rules.login.malformed')
            }
          } else {
            return this.$t('pages.signIn.rules.login.longEmail')
          }
        }
      ],
      passwordRules: [
        (v: string) => !!v || this.$t('pages.signIn.rules.pass.empty'),
        (v: string) => v.length >= 6 || this.$t('pages.signIn.rules.pass.short'),
        (v: string) => v.length <= 256 || this.$t('pages.signIn.rules.pass.long')
      ]
    }
  },
  head (): object {
    return {
      titleTemplate: this.$t('titles.signIn')
    }
  },
  computed: {
    otpTitle (): string {
      return this.$t(`pages.signIn.code.title1.${this.otpMessage.type}`, [this.otpMessage.email]).toString()
    }
  },
  created () {
    const redirectTo = this.$route.query['redirect-to']
    if (typeof redirectTo !== 'undefined') {
      this.$cookies.set('redirectTo', redirectTo)
    }
  },
  methods: {
    goBack () {
      this.step = 0
    },
    async onSubmit () {
      if (!this.form.value) {
        return
      }
      if (this.$auth.loggedIn) {
        await this.$makeAware({
          text: this.$_('Already authorized'),
          timeout: 3000
        })
        this.$go('/')
        return
      }
      let code: string | undefined
      let token: string | undefined
      if (this.step === 1) {
        code = this.form.code
        token = this.form.token
        if (!this.form.token || !this.form.code) {
          await this.$makeAware({ timeout: 1500 })
          return
        }
      }
      try {
        const res = await this.signIn(code, token)
        console.dir(res)
        await this.onSuccess(res.data.access_token, res.data.refresh_token)
      } catch (e: any) {
        if (e?.response?.status === 401) {
          this.form.code = ''
          this.form.token = e.response.data.error.token
          this.otpMessage.type = e.response.data.error.type
          this.otpMessage.email = e.response.data.error?.email || ''
          this.step = 1
          return
        }
        noWait(this.$makeSad({ text: this.$_(get_error_message(e)) }))
      }
    },
    async signIn (code: string | undefined, token: string | undefined): Promise<AxiosResponse> {
      return await this.$loader(this.$axios.post('/me/auth/sign-in', {
        login: this.form.login,
        password: this.form.password,
        guest_pc: !this.form.remember,
        code,
        token
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }), {
        timeout: 0,
        immediate: true
      })
    },
    async onSuccess (access_token: string, refresh_token: string) {
      await this.$auth.reset()
      await this.$auth.setUserToken(access_token, refresh_token)
      if (!this.$auth.loggedIn) {
        noWait(this.$makeAware({
          text: this.$_('errors.Failed to authorize')
        }))
        return
      }
      await this.$makeHappy({
        text: this.$t('messages.signInSuccess'),
        timeout: 2000
      })
      const redirectTo = this.$cookies.get('redirectTo') || this.localePath('/minecraft-profile')
      this.$cookies.remove('redirectTo')
      this.$go(redirectTo)
    }
  }
})
