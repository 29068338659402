
import Vue from 'vue'
import * as THREE from 'three'
import { v4 as uuid4 } from 'uuid'
import _ from 'lodash'
import { Easing, Queue, Tween } from '~/utils/tween'
import { formatPercent, get_error_message, noWait, playSound, sleep, updatePosition } from '~/utils/misc'
import type { Lootbox, LootboxOpened, LootboxStats, RecordPrize, ShownPrize, StoredPrize } from '~/types/lootboxes'
import { MCUser } from '~/types/user'
import { Scene, useScene } from '~/utils/lootbox/core'
import { Card, CardLoader, CardOptions, CardRarityColors } from '~/utils/lootbox/models/card'
import { Modpack, Server } from '~/types/core'

const textureLoader = new THREE.TextureLoader()

export default Vue.extend({
  layout: 'profile',
  middleware: [
    async (ctx) => {
      if (ctx.$auth.loggedIn) {
        try {
          await ctx.store.dispatch('core/fetchMinecraftUser')
        } catch (e) {
        }
      }
      await ctx.store.dispatch('core/modpacksUpdate')
      await ctx.store.dispatch('lootboxes/getLootboxes')
    }
  ],
  asyncData ({ params }) {
    const lootboxId = parseInt(params.slug)
    return { lootboxId }
  },
  data () {
    return {
      lootboxId: 0,
      lootbox: null as Lootbox | null,
      prizes: [] as RecordPrize[],
      stats: null as LootboxStats | null,
      openedLootboxes: [] as LootboxOpened[],
      openedLootboxesCount: 0,
      tier_4_chance: 0,
      tier_3_chance: 0,
      tier_2_chance: 0,
      tier_1_chance: 0,
      scene: {} as Scene,
      loader: new CardLoader(),
      cards: [] as Card[],
      workingLootbox: null as LootboxOpened | null,
      selectedCards: [] as string[],
      r: uuid4(),
      modpack: null as Modpack | null,
      selectServer: true,
      selectedServer: undefined as Server | undefined,
      stage: 0 as 0 | 1 | 2,
      prizeImages: new Map<number, string>(),
      prizeIcons: new Map<number, THREE.Texture>(),
      sounds: {
        guarantee: {} as HTMLAudioElement,
        lootbox_open: {} as HTMLAudioElement,
        prize_click: {} as HTMLAudioElement,
        prize_dust: {} as HTMLAudioElement,
        prize_take: {} as HTMLAudioElement
      }
    }
  },
  computed: {
    user (): MCUser {
      return this.$store.getters['core/mcUser']
    },
    tier_4_chance_step (): number {
      const lootbox = this.lootbox
      if (!lootbox) {
        return 0
      }
      return (lootbox.tier_4_chance_max - lootbox.tier_4_chance) / Math.max(1, lootbox.tier_4_chance_stop)
    },
    tier_4_chance_base (): number {
      const lootbox = this.lootbox
      const stats = this.stats
      if (lootbox && stats) {
        return lootbox.tier_4_chance + this.tier_4_chance_step * Math.min(stats.since_last_tier_4, lootbox.tier_4_chance_stop)
      }
      return 0
    },
    has_tier_4 (): boolean {
      const lootbox = this.lootbox
      return !!lootbox && !!lootbox.prizes.filter((p: RecordPrize) => p.rarity === 'tier_4').length
    },
    has_tier_3 (): boolean {
      const lootbox = this.lootbox
      return !!lootbox && !!lootbox.prizes.filter((p: RecordPrize) => p.rarity === 'tier_3').length
    },
    has_tier_2 (): boolean {
      const lootbox = this.lootbox
      return !!lootbox && !!lootbox.prizes.filter((p: RecordPrize) => p.rarity === 'tier_2').length
    },
    has_tier_1 (): boolean {
      const lootbox = this.lootbox
      return !!lootbox && !!lootbox.prizes.filter((p: RecordPrize) => p.rarity === 'tier_1').length
    },
    tier_3_guarantee (): { opened: number, total: number } {
      const lootbox = this.lootbox
      const stats = this.stats
      if (!lootbox || !stats) {
        return {
          opened: 0,
          total: 0
        }
      }
      return {
        opened: stats.since_last_tier_3,
        total: lootbox.tier_3_guarantee
      }
    },
    tier_4_guarantee (): { opened: number, total: number } {
      const lootbox = this.lootbox
      const stats = this.stats
      if (!lootbox || !stats) {
        return {
          opened: 0,
          total: 0
        }
      }
      return {
        opened: stats.since_last_tier_4,
        total: lootbox.tier_4_guarantee
      }
    },
    opened (): number {
      const lootbox = this.lootbox
      if (!lootbox) {
        return 0
      }
      return this.$store.getters['lootboxes/lootboxesOpened'].filter((l: LootboxOpened) => l.id === lootbox.id)
    },
    rateUp () {
      const res = {
        legendary: null as RecordPrize | null,
        epic: null as RecordPrize | null
      }
      if (!this.lootbox) {
        return res
      }
      for (const prize of this.lootbox.prizes) {
        if (!prize.rate_up) {
          continue
        }
        if (prize.rarity === 'tier_4') {
          res.legendary = prize
        }
        if (prize.rarity === 'tier_3') {
          res.epic = prize
        }
      }
      return res
    },
    servers (): Server[] {
      if (!this.modpack) {
        return []
      }
      return this.modpack.servers
    }
  },
  watch: {
    lootbox () {
      if (process.client) {
        this.updateIcons()
      }
    },
    selectedServer () {
      if (this.selectedServer) {
        this.afterSync()
      }
    }
  },
  async created () {
    await this.afterSync()
  },
  mounted () {
    this.sounds.guarantee = document.getElementById('audio_guarantee') as HTMLAudioElement
    this.sounds.lootbox_open = document.getElementById('audio_lootbox_open') as HTMLAudioElement
    this.sounds.prize_click = document.getElementById('audio_prize_click') as HTMLAudioElement
    this.sounds.prize_dust = document.getElementById('audio_prize_dust') as HTMLAudioElement
    this.sounds.prize_take = document.getElementById('audio_prize_take') as HTMLAudioElement
    this.sounds.guarantee.load()
    this.sounds.lootbox_open.load()
    this.sounds.prize_click.load()
    this.sounds.prize_dust.load()
    this.sounds.prize_take.load()
    this.sounds.guarantee.volume = 0.4
    this.sounds.lootbox_open.volume = 0.8
    this.sounds.prize_click.volume = 0.80
    this.sounds.prize_dust.volume = 0.3
    this.sounds.prize_take.volume = 0.5
    updatePosition()
    this.$nextTick(this.init)
  },
  methods: {
    formatPercent,
    async loadMCUser () {
      if (this.$auth.loggedIn) {
        try {
          await this.$store.dispatch('core/fetchMinecraftUser')
        } catch (e) {
        }
      }
    },
    showModal () {
      this.selectServer = true
      updatePosition()
    },
    async init () {
      await this.loader.init()
      const canvas = this.$refs.canvas as HTMLCanvasElement
      this.scene = await useScene({
        canvas,
        pixelRatio: devicePixelRatio || 1
      })
      this.start()
    },
    start () {
      this.stage = 0
      this.selectedCards.splice(0, this.selectedCards.length)
      this.r = uuid4()
      this.removeCards()
      this.addCard('', {})
      noWait(this.moveCamera())
      noWait(this.moveCards())
    },
    async afterSync () {
      const lootboxes = this.$store.getters['lootboxes/lootboxes'].filter((l: Lootbox) => l.id === this.lootboxId)
      const lootbox = this.lootbox = lootboxes ? lootboxes[0] : null
      if (!lootbox) {
        return
      }
      this.updatePrizes()
      this.modpack = this.$store.getters['core/modpacks'].filter((m: Modpack) => m.id === lootbox.modpack_id)[0]
      this.stats = await this.$store.dispatch('lootboxes/getLootboxStats', { lootbox_id: lootbox.id })
      if (this.selectedServer) {
        const {
          lootboxes: openedLootboxes,
          count
        } = await this.$store.dispatch('lootboxes/getLootboxesOpened', {
          lootbox_id: lootbox.id,
          server_id: this.selectedServer.id
        })
        if (openedLootboxes) {
          this.openedLootboxes.splice(0, this.openedLootboxes.length, ...openedLootboxes)
          this.openedLootboxesCount = count
        }
      }
      this.workingLootbox = this.openedLootboxes.length ? this.openedLootboxes[0] : null
      this.updateChances()
    },
    async sync () {
      await this.loadMCUser()
      await this.$store.dispatch('lootboxes/getLootboxes')
      await this.afterSync()
    },
    async buyLootbox () {
      const lootbox = this.lootbox
      try {
        if (!lootbox) {
          await this.$makeSad({ text: 'Ошибка загрузки набора карточек' })
          return
        }
        if (!this.$auth.loggedIn) {
          const auth = await this.$makeSad({
            title: 'Необходима авторизация',
            text: 'Пожалуйста, войдите в аккаунт перед покупкой набора.',
            confirm: 'Войти',
            cancel: 'Отмена'
          })
          if (auth) {
            window.open(this.localePath('/sign-in'))
          }
          return
        }
        if (this.user.gold_balance < lootbox.price) {
          const deposit = await this.$makeSad({
            title: 'Недостаточно средств',
            text: 'На вашем счету недостаточно средств.',
            confirm: 'Пополнить',
            cancel: 'Отмена'
          })
          if (deposit) {
            window.open(this.localePath('/donate'))
          }
          return
        }
        if (!this.selectedServer) {
          await this.$makeAware({
            title: 'Не выбран сервер',
            text: 'Перед покупкой набора, необходимо выбрать сервер'
          })
          this.showModal()
          return
        }
        const res = await this.$loader(this.$axios.post<{ lootbox: LootboxOpened }>('/minecraft/lootboxes/open/', {
          server_id: this.selectedServer.id,
          lootbox_id: lootbox.id,
          r: this.r
        }), {
          timeout: 0,
          immediate: true
        })
        this.$store.dispatch('lootboxes/getLootboxStats', { lootbox_id: lootbox.id })
          .then((stats: LootboxStats) => {
            this.stats = stats
            this.updateChances()
          })
        this.r = uuid4()
        this.openedLootboxes.push(res.data.lootbox)
        this.openedLootboxesCount += 1
        if (!this.workingLootbox) {
          this.workingLootbox = this.openedLootboxes[0]
          this.start()
        }
        this.$store.commit('core/updateMinecraftUser', { gold_balance: this.user.gold_balance - lootbox.price })
      } catch (e: unknown) {
        await this.$makeSad({
          title: 'Failed to buy lootbox',
          text: get_error_message(e)
        })
      }
    },
    async openLootbox () {
      const working = this.workingLootbox
      if (!working || this.stage !== 0) {
        return
      }
      this.stage = 1
      noWait((async () => {
        await sleep(1000 / 24 * 19)
        await playSound(this.sounds.lootbox_open)
      })())
      await this.cards[0].open()
      this.removeCards()
      for (const prize of working.shown_prizes.prizes) {
        const card = this.addCard(prize.prize_id, {
          rarity: prize.rarity || 'unknown'
        })
        card.setOpened()
        if (prize.rarity) {
          card.shineOn(CardRarityColors[prize.rarity])
        }
      }
      if (working.tier_4_guarantee) {
        noWait(playSound(this.sounds.guarantee))
      }
      await Promise.all([this.moveCamera(), this.moveCards()])
      for (const card of this.cards) {
        this.enableCardShine(card)
        this.enableCardSelection(card)
      }
      const wait: Promise<any>[] = []
      for (const prize of working.chosen_prizes.prizes) {
        const card = this.cards.filter(e => e.id === prize.prize_id)[0]
        wait.push(this.selectCard(card, true))
      }
      await Promise.all(wait)
    },
    async selectCard (card: Card, skipAction: boolean = false) {
      if (!~this.cards.indexOf(card)) {
        return
      }
      let working = this.workingLootbox as LootboxOpened
      if (this.selectedCards.length >= working.choices_select) {
        return
      }
      this.disableCardShine(card)
      this.disableCardSelection(card)
      if (this.selectedCards.length + 1 >= working.choices_select) {
        this.cards.forEach((c) => {
          this.disableCardShine(c)
          this.disableCardSelection(c)
        })
      }
      try {
        if (!skipAction) {
          const res = await this.$loader(this.$axios.post<{
            lootbox: LootboxOpened
          }>('/minecraft/lootboxes/choose-prize/', {
            open_id: working.id,
            prize: card.id
          }), {
            timeout: 0,
            immediate: true
          })
          working = this.workingLootbox = res.data.lootbox
        }
        this.selectedCards.push(card.id)
        await this.setSelectedCard(card)
        if (working.status !== 'opened') {
          await this.doneSelecting()
        }
      } catch (e: unknown) {
        await this.$makeSad({
          title: 'Не удалось выбрать карточку',
          text: get_error_message(e)
        })
        this.enableCardShine(card)
        this.enableCardSelection(card)
        if (this.selectedCards.length + 1 >= working.choices_select) {
          this.cards.forEach((c) => {
            if (!~this.selectedCards.indexOf(c.id)) {
              this.enableCardShine(c)
              this.enableCardSelection(c)
            }
          })
        }
      }
    },
    async setSelectedCard (card: Card) {
      const working = this.workingLootbox as LootboxOpened
      const prizes = working.status === 'opened' ? working.chosen_prizes.prizes : working.shown_prizes.prizes
      const prize = prizes.filter(e => e.prize_id === card.id)[0]
      await card.setRarity(prize.rarity)
      card.options.name = prize.name
      card.options.price = prize.dust_price
      const icon = this.prizeIcons.get(prize.id)
      if (icon) {
        card.options.icon = icon
      }
      if (prize.prize_type === 'currency') {
        card.options.amount = prize.meta.amount
      } else if (prize.prize_type === 'item') {
        card.options.amount = prize.meta.count
      } else if (prize.prize_type === 'role') {
        card.options.amount = prize.meta.months
      }
    },
    async doneSelecting () {
      const working = this.workingLootbox
      if (!working || this.stage >= 2) {
        return
      }
      this.stage = 2
      await sleep(15)
      // Colorize non-selected cards
      await Promise.all(this.cards.filter(c => !~this.selectedCards.indexOf(c.id)).map(c => this.setSelectedCard(c)))
      // Reveal all cards
      await Promise.all(this.cards.map(c => c.select()))
      await sleep(100)
      // "Dust" non-selected cards
      await Promise.all(this.cards.filter(c => !~this.selectedCards.indexOf(c.id)).map(c => c.dust()))
      // Remove non-selected cards
      for (const card of [...this.cards]) {
        if (!~this.selectedCards.indexOf(card.id)) {
          this.removeCard(card)
        }
      }
      await sleep(100)
      // Reset viewport
      await Promise.all([this.moveCards(), this.moveCamera()])
      await sleep(30)
      for (const prize of working.dusted_prizes.prizes) {
        const card = this.cards.filter(e => e.id === prize.prize_id)[0]
        await this.takeOrDust(card, true, true)
      }
      for (const prize of working.given_prizes.prizes) {
        const card = this.cards.filter(e => e.id === prize.prize_id)[0]
        await this.takeOrDust(card, false, true)
      }
      await sleep(15)
      for (const card of this.cards) {
        this.enableCardTaking(card)
      }
    },
    async takeOrDust (card: Card, dust: boolean = false, skipAction: boolean = false) {
      try {
        let working = this.workingLootbox as LootboxOpened
        this.disableCardTaking(card)
        if (!skipAction) {
          const res = await this.$loader(this.$axios.post<{
            lootbox: LootboxOpened
          }>('/minecraft/lootboxes/take-or-dust-prize/', {
            open_id: working.id,
            prize: card.id,
            dust
          }), {
            timeout: 0,
            immediate: true
          })
          working = this.workingLootbox = res.data.lootbox
          const prize = working.chosen_prizes.prizes.filter((p: StoredPrize) => p.prize_id === card.id)[0]
          if (dust) {
            this.$store.commit('core/updateMinecraftUser', { gold_balance: this.user.gold_balance + prize.dust_price })
          } else if (prize.prize_type === 'currency') {
            this.$store.commit('core/updateMinecraftUser', { gold_balance: this.user.gold_balance + prize.meta.amount })
          }
        }
        if (dust) {
          noWait(playSound(this.sounds.prize_dust))
          await card.dust()
        } else {
          noWait((async () => {
            await sleep(520)
            await playSound(this.sounds.prize_take)
          })())
          await card.take()
        }
        this.removeCard(card)
        await Promise.all([this.moveCamera(), this.moveCards()])
        if (working.status === 'resolved') {
          await this.doneResolving()
        }
      } catch (e: unknown) {
        await this.$makeSad({
          title: 'Не удалось ' + (dust ? 'распылить' : 'забрать') + ' приз',
          text: get_error_message(e)
        })
        this.enableCardTaking(card)
      }
    },
    async doneResolving () {
      if (this.stage !== 2) {
        return
      }
      this.workingLootbox = null
      this.start()
      await this.$loader(this.sync(), {
        timeout: 0,
        immediate: true
      })
    },
    addCard (id: string, opts: CardOptions): Card {
      const card = new Card(this.loader, id, opts)
      this.scene.activeScene.add(card.parentG)
      this.cards.push(card)
      return card
    },
    enableCardShine (card: Card) {
      const working = this.workingLootbox
      if (working) {
        const prize = working.shown_prizes.prizes.filter((p: ShownPrize) => p.prize_id === card.id)[0]
        if (prize.rarity) {
          card.shineOn(CardRarityColors[prize.rarity])
          return
        }
      }
      this.scene.addEvent('mousemove', card.g.uuid, [card.g], true, () => {
        card.shineOn(CardRarityColors[card.options.rarity])
      }, () => {
        card.shineOff()
      })
    },
    disableCardShine (card: Card) {
      this.scene.delEvent('mousemove', card.g.uuid)
      card.shineOff()
    },
    enableCardSelection (card: Card) {
      this.scene.addEvent('click', card.g.uuid, [card.g], true, async () => {
        noWait(playSound(this.sounds.prize_click))
        await this.selectCard(card)
      })
    },
    disableCardSelection (card: Card) {
      this.scene.delEvent('click', card.g.uuid)
    },
    enableCardTaking (card: Card) {
      this.scene.addEvent('click', card.back.bottom.uuid, [card.back.bottom], true, () => {
        this.takeOrDust(card, true)
      })
      this.scene.addEvent('click', card.back.take.uuid, [card.back.take], true, () => {
        this.takeOrDust(card, false)
      })
    },
    disableCardTaking (card: Card) {
      this.scene.delEvent('click', card.back.bottom.uuid)
      this.scene.delEvent('click', card.back.take.uuid)
    },
    removeCard (card: Card) {
      const i = this.cards.indexOf(card)
      this.cards.splice(i, 1)
      card.parentG.scale.set(0, 0, 0)
      this.disableCardShine(card)
      this.disableCardSelection(card)
      this.disableCardTaking(card)
    },
    removeCards () {
      for (const card of [...this.cards]) {
        this.removeCard(card)
      }
      this.scene.activeScene.clear()
      this.cards.splice(0, this.cards.length)
    },
    moveCamera () {
      return new Promise<void>((resolve) => {
        if (!this.cards.length) {
          return resolve()
        }
        new Queue(new Tween({
          target: { z: this.scene.camera.position.z },
          to: { z: Math.max(45 * (this.cards.length + 1), 160) + 5 },
          duration: 300,
          easing: Easing.Cubic.InOut,
          onUpdate: e => this.scene.camera.position.setZ(e.z),
          onComplete: () => resolve()
        })).run()
      })
    },
    moveCards () {
      return new Promise<void>((resolve) => {
        const amount = this.cards.length
        if (!amount) {
          return resolve()
        }
        for (let i = 0; i < amount; i++) {
          const card = this.cards[i]
          new Queue(new Tween({
            target: { x: card.parentG.position.x },
            to: { x: (i - (amount - 1) / 2) * 22 },
            duration: 300,
            onUpdate: e => card.parentG.position.setX(e.x),
            onComplete: () => resolve()
          })).run()
        }
      })
    },
    updateChances () {
      const lootbox = this.lootbox
      if (!lootbox) {
        this.tier_4_chance = 0
        this.tier_3_chance = 0
        this.tier_2_chance = 0
        this.tier_1_chance = 0
        return
      }
      this.tier_4_chance = this.tier_4_chance_base
      this.tier_3_chance = lootbox.tier_3_chance
      this.tier_2_chance = lootbox.tier_2_chance
      this.tier_1_chance = 100 - this.tier_4_chance - this.tier_3_chance - this.tier_2_chance
      if (!this.has_tier_4) {
        this.tier_3_chance += this.tier_4_chance
        this.tier_4_chance = 0
      }
      if (!this.has_tier_3) {
        this.tier_2_chance += this.tier_3_chance
        this.tier_3_chance = 0
      }
      if (!this.has_tier_2) {
        this.tier_1_chance += this.tier_2_chance
        this.tier_2_chance = 0
      }
      if (!this.has_tier_1) {
        this.tier_2_chance += this.tier_1_chance
        this.tier_1_chance = 0
      }
      if (!this.has_tier_2) {
        this.tier_3_chance += this.tier_2_chance
        this.tier_2_chance = 0
      }
      if (!this.has_tier_3) {
        this.tier_4_chance += this.tier_3_chance
        this.tier_3_chance = 0
      }
    },
    async updateIcons () {
      if (!this.lootbox) {
        return
      }
      const chunks = _.chunk(this.lootbox.prizes, 10)
      for (const chunk of chunks) {
        const wg: Promise<void>[] = []
        for (const prize of chunk) {
          wg.push((async () => {
            if (!prize.icon || this.prizeImages.get(prize.id)) {
              return
            }
            const res = await fetch(prize.icon, { credentials: 'omit' })
            const imgURL = URL.createObjectURL(await res.blob())
            this.prizeImages.set(prize.id, imgURL)
            textureLoader.loadAsync(imgURL).then((texture: THREE.Texture) => {
              this.prizeIcons.set(prize.id, texture)
            })
          })())
        }
        await Promise.all(wg)
        this.updatePrizes()
        await sleep(50)
      }
      this.updatePrizes()
    },
    updatePrizes () {
      if (!this.lootbox) {
        return
      }
      let prizes: RecordPrize[] = []
      for (const prize of this.lootbox.prizes) {
        prizes.push(Object.assign({}, prize))
      }
      const r = {
        tier_1: 0,
        tier_2: 1,
        tier_3: 2,
        tier_4: 3,
        unknown: -999
      }
      prizes = prizes.sort((a, b) => r[b.rarity] - r[a.rarity])
      for (let i = 0; i < prizes.length; i++) {
        prizes[i].icon = this.prizeImages.get(prizes[i].id) || null
      }
      this.prizes.splice(0, this.prizes.length, ...prizes)
    }
  }
})
