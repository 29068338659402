
import Vue from 'vue'

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'MinecraftRules',
  head () : object {
    return {
      titleTemplate: '%s | ' + this.$t('documents.minecraft_rules')
    }
  }
})
