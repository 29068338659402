
import Vue from 'vue'
import { MCUser } from '~/types/user'
import { StrDict } from '~/types'
import { get_error_message, noWait } from '~/utils/misc'

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'MinecraftProfile',
  layout: 'profile',
  middleware: [
    'auth'
  ],
  head (): object {
    return {
      titleTemplate: this.$t('titles.minecraftProfile')
    }
  },
  computed: {
    mcUser (): MCUser {
      return this.$store.getters['core/mcUser'] as MCUser
    },
    skin (): string | null {
      if (this.mcUser && this.mcUser.skin) {
        // noinspection HttpUrlsUsage
        return this.mcUser.skin
      }
      return null
    }
  },
  async beforeMount () {
    await this.loadMCUser()
  },
  methods: {
    async loadMCUser () {
      if (this.$auth.loggedIn) {
        try {
          await this.$store.dispatch('core/fetchMinecraftUser')
        } catch (e) {
          console.error(e)
        }
      }
    },
    initSkinUpload () {
      const uploader = this.$refs.skinUploader as HTMLInputElement
      // noinspection JSUnresolvedFunction
      uploader.click()
    },
    async uploadSkin () {
      const uploader = this.$refs.skinUploader as HTMLInputElement
      try {
        // noinspection JSUnresolvedVariable
        const files = uploader.files
        if (!files || !files.length) {
          return
        }
        const file = files[0]
        if (file.size > 3145728) {
          this.$makeAware({ text: this.$t('errors.SkinSizeLarge') }).then().catch(console.error)
          return
        }
        const formData = new FormData()
        formData.append('skin', file)
        await this.$loader(this.$axios.post('/minecraft/user/skin/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          withCredentials: true
        }), {
          immediate: true,
          timeout: 0
        })
        await this.loadMCUser()
      } catch (e: any) {
        noWait(this.$makeSad({ text: this.$_(get_error_message(e as StrDict)) }))
      } finally {
        uploader.value = ''
      }
    },
    async resetSkin () {
      if (await this.$makeSure()) {
        await this.$axios.delete('/minecraft/user/skin/', {
          withCredentials: true
        })
        await this.loadMCUser()
      }
    }
  }
})
