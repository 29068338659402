
import Modal from './Modal'
import { DownloadOptions, Platforms } from '~/types/core'

// noinspection JSUnusedGlobalSymbols
export default Modal.extend({
  name: 'DownloadModal',
  data () {
    return {
      name: 'download'
    }
  },
  methods: {
    disabled (platform: Platforms): boolean {
      return !(this.download && this.download[platform])
    },
    link (platform: Platforms): string {
      return `${this.$config.domains.me_site}/desktop/download/installer?platform=${platform}`
    },
    hide (): void {
      this.show = false
    }
  },
  computed: {
    download (): DownloadOptions | Record<string, unknown> {
      return this.$store.getters['core/downloadOptions']
    }
  }
})
