
import Vue from 'vue'
import { get_error_message, hashObject, noWait } from '~/utils/misc'
import { TextRule } from '~/types'

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'RestoreAccessConfirm',
  layout: 'form',
  middleware: 'auth',
  auth: 'guest',
  data () {
    return {
      form: {
        value: false,
        token: '',
        password: '',
        passwordRepeat: ''
      },
      showPassword: false,
      showPasswordRepeat: false,
      passwordRules: [
        (v: string) => !!v || this.$t('pages.restoreAccess.rules.pass.empty'),
        (v: string) => v.length >= 6 || this.$t('pages.restoreAccess.rules.pass.short'),
        (v: string) => v.length <= 256 || this.$t('pages.restoreAccess.rules.pass.long')
      ]
    }
  },
  head (): object {
    return {
      titleTemplate: this.$t('titles.restoreAccess')
    }
  },
  computed: {
    passwordRepeatRules (): TextRule[] {
      return [
        (v: string) => !!v || this.$t('pages.restoreAccess.rules.pass2.empty'),
        (v: string) => v === this.form.password || this.$t('pages.restoreAccess.rules.pass2.mismatch')
      ]
    }
  },
  created () {
    const redirectTo = this.$route.query['redirect-to']
    if (typeof redirectTo !== 'undefined') {
      this.$cookies.set('redirectTo', redirectTo)
    }

    this.$nextTick(() => {
      const hash = hashObject()
      if ('token' in hash) {
        this.form.token = hash.token
      } else {
        this.$go('/restore-access')
      }
    })
  },
  methods: {
    async onSubmit () {
      if (!this.form.value) {
        return
      }
      try {
        await this.$loader(this.restoreAccess(), {
          timeout: 0,
          immediate: true
        })
        await this.onSuccess()
      } catch (e: any) {
        noWait(this.$makeSad({ text: this.$_(get_error_message(e)) }))
      }
    },
    async restoreAccess (): Promise<void> {
      if (this.$auth.loggedIn) {
        await this.$makeAware({
          text: 'Already authorized',
          timeout: 3000
        })
        this.$go('/')
      }
      const res = await this.$axios.post(
        '/me/auth/restore-access/confirm',
        {
          new_password: this.form.password,
          new_password_repeat: this.form.passwordRepeat,
          token: this.form.token
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      await this.$auth.reset()
      await this.$auth.setUserToken(res.data.access_token, res.data.refresh_token)
    },
    async onSuccess () {
      await this.$makeHappy({
        text: this.$t('messages.restoreAccessSuccess'),
        timeout: 2000
      })
      const redirectTo = this.$cookies.get('redirectTo') || this.localePath('/')
      this.$cookies.remove('redirectTo')
      this.$go(redirectTo)
    }
  }
})
