
import type { PropType } from 'vue'
import Vue from 'vue'
import type { Lootbox, LootboxStats, RecordPrize } from '~/types/lootboxes'
import { formatPercent } from '~/utils/misc'

export default Vue.extend({
  props: {
    lootbox: {
      type: Object as PropType<Lootbox>,
      required: true
    }
  },
  data () {
    return {
      more: false,
      opened: 0,
      stats: {} as LootboxStats,
      statsInterval: 0
    }
  },
  computed: {
    tier_4_chance (): number {
      return this.lootbox.tier_4_chance + this.tier_4_chance_step * this.stats.since_last_tier_4
    },
    tier_4_chance_step (): number {
      const lootbox = this.lootbox
      return (lootbox.tier_4_chance_max - lootbox.tier_4_chance) / Math.max(1, lootbox.tier_4_chance_stop)
    },
    tier_3_guarantee (): { opened: number, total: number } {
      return {
        opened: this.stats.since_last_tier_3,
        total: this.lootbox.tier_3_guarantee
      }
    },
    tier_4_guarantee (): { opened: number, total: number } {
      return {
        opened: this.stats.since_last_tier_4,
        total: this.lootbox.tier_4_guarantee
      }
    },
    rateUp () {
      const res = {
        legendary: null as RecordPrize | null,
        epic: null as RecordPrize | null
      }
      for (const prize of this.lootbox.prizes) {
        if (!prize.rate_up) {
          continue
        }
        if (prize.rarity === 'tier_4') {
          res.legendary = prize
        }
        if (prize.rarity === 'tier_3') {
          res.epic = prize
        }
      }
      return res
    }
  },
  async created () {
    await this.updateStats()
  },
  mounted () {
    this.statsInterval = window.setInterval(this.updateStats, 1000 * 15)
  },
  beforeDestroy () {
    clearInterval(this.statsInterval)
  },
  methods: {
    formatPercent,
    async updateStats () {
      this.stats = await this.$store.dispatch('lootboxes/getLootboxStats', { lootbox_id: this.lootbox.id })
      const { count } = await this.$store.dispatch('lootboxes/getLootboxesOpened', { lootbox_id: this.lootbox.id })
      this.opened = count
    }
  }
})
