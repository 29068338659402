
import Vue from 'vue'
import '~/utils/marked/marked.scss'
import 'highlight.js/styles/base16/onedark.css'

export default Vue.extend({
  name: 'MarkdownView',
  props: {
    content: {
      type: String,
      default: ''
    }
  }
})
