
import type { Component } from 'vue'
import Vue from 'vue'
// @ts-ignore
import { DiscordIcon, TelegramIcon, VkIcon, YouTubeIcon } from 'vue-simple-icons'

// noinspection JSUnusedGlobalSymbols
export default Vue.extend({
  name: 'SiteFooter',
  components: {
    DiscordIcon: DiscordIcon as Component,
    VkIcon: VkIcon as Component,
    YouTubeIcon: YouTubeIcon as Component,
    TelegramIcon: TelegramIcon as Component
  },
  computed: {
    logo (): string {
      return '/img/cifrazia' + (this.$vuetify.theme.dark ? '' : '-black') + '.svg'
    },
    copyrightText (): string {
      return `Copyright © Cifrazia 2022-${(new Date()).getUTCFullYear()}`
    }
  }
})
