
import Vue from 'vue'
import converter from 'discord-emoji-converter/dist/discord-emoji-converter.min'
import unicodeSubstring from 'unicode-substring'
import lodash from 'lodash'
import type { OurProject, OurProjectCategory } from '~/types/our_projects'
import { Modpack } from '~/types/core'

const PAGE_SIZE = 20
export default Vue.extend({
  name: 'OurProjects',
  layout: 'landing',
  middleware: [
    async ({ store }) => {
      await store.dispatch('core/modpacksUpdate')
    }
  ],
  async asyncData ({ $axios }) {
    let projectCats: OurProjectCategory[] = []
    try {
      const res = await $axios.get<{ categories: OurProjectCategory[] }>('/minecraft/our_projects/categories')
      projectCats = res.data.categories
    } catch (e) {
    }
    return { projectCats }
  },
  data () {
    return {
      projects: [] as OurProject[],
      projectCats: [] as OurProjectCategory[],
      selectedCategory: 0,
      selectedModpacks: [] as number[],
      showModpacks: true,
      page: 1,
      hasMore: true,
      loading: false,
      searchResetDelay: 0,
      watch: false
    }
  },
  computed: {
    modpacks (): Modpack[] {
      return this.$store.getters['core/modpacks']
    },
    modpackIds (): number[] {
      return this.modpacks.map(m => m.id)
    }
  },
  watch: {
    selectedCategory (a, b) {
      if (this.watch && a !== b) {
        this.updatePath()
        this.reset()
        this.loadOurProjects()
      }
    },
    selectedModpacks () {
      if (this.watch) {
        this.updatePath()
        this.reset()
        this.loadOurProjects()
      }
    }
  },
  async created () {
    this.reset()
    this.loadPath()
    await this.loadOurProjects()
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll () {
      const l = document.querySelector('#scrollLoader') as HTMLDivElement
      if (l.getBoundingClientRect().y - window.innerHeight - 100 < 0) {
        this.loadOurProjects()
      }
    },
    loadPath () {
      let cat = this.$route.query.cat || ''
      cat = cat instanceof Array ? cat[0] || '' : cat
      this.selectedCategory = parseInt(cat) || 0
      let modpacks = this.$route.query.modpacks || ''
      modpacks = modpacks instanceof Array ? modpacks[0] || '' : modpacks
      this.selectedModpacks = modpacks.split(',')
        .map(v => parseInt(v))
        .filter(v => Number.isSafeInteger(v))
        .filter(v => ~this.modpackIds.indexOf(v))
      this.$nextTick(() => {
        this.watch = true
      })
    },
    async updatePath () {
      const query: Record<string, string> = {}
      if (this.selectedCategory > 0) {
        query.cat = `${this.selectedCategory}`
      }
      if (this.selectedModpacks.length) {
        query.modpacks = this.selectedModpacks.join(',')
      }
      if (!lodash.isEqual(query, this.$route.query)) {
        await this.$router.push({
          path: this.$route.path,
          query
        })
      }
    },
    reset () {
      this.loading = true
      this.hasMore = true
      this.page = 1
      this.projects.splice(0, this.projects.length)
      this.loading = false
    },
    async loadOurProjects () {
      if (!this.hasMore || this.loading) {
        return
      }
      try {
        const res = await this.$axios.get<{ projects: OurProject[] }>('/minecraft/our_projects/', {
          params: {
            page: this.page,
            cat: this.selectedCategory,
            modpacks: this.selectedModpacks.join(',')
          }
        })
        if (res.data.projects.length < PAGE_SIZE) {
          this.hasMore = false
        }
        for (const project of res.data.projects) {
          if (!~this.projects.map(g => g.id).indexOf(project.id)) {
            this.projects.push(project)
          }
        }
        this.page++
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    getEmoji: converter.getEmoji,
    unicodeSubstring,
    toggleModpack (modpack: number): void {
      this.toggleArray(this.selectedModpacks, modpack)
    },
    toggleArray<T = any> (array: T[], value: T): void {
      const index = array.indexOf(value)
      if (index === -1) {
        array.push(value)
      } else {
        array.splice(index, 1)
      }
    }
  }
})
